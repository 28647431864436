import React from "react"
import { Pill } from "@/components/general-components"
import styled from "styled-components"
import { Link } from "gatsby"
import kebabCase from "lodash.kebabcase"

const Container = styled.div`
    display: flex;
    flex-directin: row;
    gap: var(--size-3);
    padding-block: var(--size-3);
`

const TagList = ({ tags }) => (
    <Container>
        {tags.map(tag => (
            <Pill key={tag}>
                <Link to={`/tag/${kebabCase(tag)}`}>{tag}</Link>
            </Pill>
        ))}
    </Container>
)

export default TagList
