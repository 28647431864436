import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

const sidebar = [
    { tag: "meta", title: "General", alt: "general" },
    { tag: "center", title: "Centralia" },
    { tag: "north", title: "Northern Mountains" },
    { tag: "east", title: "Eastern Wizards" },
    { tag: "south", title: "Southern Seas" },
    { tag: "west", title: "Western Plains" },
]

const Sidebar = styled.aside`
    display: flex;
    flex-direction: column;
    gap: var(--size-2);
    > section {
        header {
            font-variant: small-caps;
            font-weight: var(--font-weight-5);
        }
        > div {
            padding-inline-start: var(--size-3);
        }
    }
`

const SidebarSection = ({ title, nodes }) => (
    <section>
        <header>{title}</header>
        {nodes.map(n => (
            <div key={n.slug}>
                <Link to={`/${n.slug}`}>{n.title}</Link>
            </div>
        ))}
    </section>
)

const DungeonSidebar = () => {
    const stuff = useStaticQuery(
        graphql`
            query {
                allMdx(
                    filter: {
                        fileAbsolutePath: { regex: "/vault/" }
                        frontmatter: {
                            tags: { in: "dungeon23" }
                            publish: { ne: false }
                        }
                    }
                ) {
                    edges {
                        node {
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                date
                                tags
                            }
                        }
                    }
                }
            }
        `
    )

    const {
        allMdx: { edges = [] },
    } = stuff

    const nodes = edges.map(edge => ({
        title: edge.node.frontmatter.title,
        data: edge.node.frontmatter.date,
        tags: edge.node.frontmatter.tags,
        slug: edge.node.fields.slug,
    }))

    return (
        <Sidebar>
            {sidebar.map(section => {
                const sectionNodes = nodes.filter(
                    n =>
                        n.tags.includes(section.tag) ||
                        (section.alt && n.tags.includes(section.alt))
                )
                return (
                    <SidebarSection
                        title={section.title}
                        nodes={sectionNodes}
                    />
                )
            })}
        </Sidebar>
    )
}

export default DungeonSidebar
