import React from "react"
import { graphql } from "gatsby"
import MdxProvider from "@/components/MDXProvider"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "@/components/Layout"

import Maturity from "@/components/Maturity"
import styled from "styled-components"
import TagList from "@/components/TagList"
import DungeonSidebar from "@/components/DungeonSidebar"

const Subhead = styled.div`
    display: flex;
    gap: var(--size-3);
    flex-direction: row;
    padding-block-start: var(--size-1);
    padding-block-end: var(--size-3);
`
const Article = styled.article`
    p {
        margin-bottom: var(--size-3);
    }
    a {
        font-weight: var(--font-weight-5);
    }
`

const PostTemplate = ({ data }) => {
    const postNode = data.mdx
    const post = data.mdx.fields
    const { tags } = postNode.frontmatter
    const postTitle = data.mdx.frontmatter.title
    // const pageTitle = `${postTitle} - ${siteTitle}`;

    const showAside = tags.includes("dungeon23")

    return (
        <MdxProvider>
            <Layout>
                {showAside && <DungeonSidebar />}
                <Article>
                    <h2>{postTitle}</h2>
                    <Subhead>
                        {post.date}
                        <Maturity stage={post.stage} />
                    </Subhead>
                    <MDXRenderer>{postNode.body}</MDXRenderer>
                    <TagList tags={tags} />
                </Article>
            </Layout>
        </MdxProvider>
    )
}

export const query = graphql`
    query PostBySlug($slug: String!) {
        mdx(fields: { slug: { eq: $slug } }) {
            ...post
        }
    }
`

export default PostTemplate
